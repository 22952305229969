
import { defineComponent } from 'vue'
import insureCompany from './insure-company.vue'
import {
    insureDeductionExport,
    insureDeductionDetailExport,
    insuranceDetailedExport
} from '@/api/export'
import store from '@/store/index'
import {
    insureDeduction,
    insureDeductionDetailListDto,
    insuranceRecharge,
    insureBalance,
    checkStatusWesure
} from '@/api/insure-deduction'
import batchInsureVue from '@/components/batch-insure.vue'
import user from '@/utils/user'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
import popupRecharge from '@/components/popup-recharge.vue'
import { ElMessage } from 'element-plus'
import { getUserInfo, getqueryreceiptaccountapi } from '@/api/base'
export default defineComponent({
    name: 'InsureDeduction',
    components: {
        batchInsureVue,
        popupRecharge,
        insureCompany
    },
    data() {
        return {
            showtcflag: false,
            projectTime: '',
            pageSize: 10,
            pageNum: 1,
            total: 0 as number,
            tableData: [] as insureDeductionDetailListDto[],
            searchText: '',
            selects: [] as string[],
            insureCount: {} as {
                amount: number;
                insureProjectCount: number;
                policyPeopleCount: number;
            },
            isGsc: false,
            isInsure: false,
            isRecharge: false,
            openBank: '',
            bankNo: '',
            bankName: '',
            balanceObj: {} as any,
            canttb: false,
            showNotice: true
        }
    },
    async created() {
        console.log(user.isForbidInsure, 'user.isForbidInsure')
        user.waitLogin().then(() => {
            this.isGsc = user.isGsc
        })
        const data: any = await getUserInfo()
        const res: any = await getqueryreceiptaccountapi({ productType: 2 })
        console.log(res, 'hasfgsyfsuyguysgh')
        this.openBank = res.openBank
        this.bankNo = res.bankNo
        this.bankName = res.bankName
        console.log(data.isForbidInsure, 'datadtaat')
        sessionStorage.setItem(
            'dayinsuretcflag',
            JSON.stringify(data.isForbidInsure)
        )
        this.canttb = data.isForbidInsure
        if (sessionStorage.getItem('dayinsuretcflag') === 'true') {
            this.showtcflag = true
        } else {
            this.showtcflag = false
        }
        this.insureDeductionList()
        this.getInsureBalance()
    },
    methods: {
        closetc() {
            this.showtcflag = false
            sessionStorage.setItem('dayinsuretcflag', 'true')
        },
        daycz() {
            if (this.balanceObj.isOpenWesure === 1) {
                this.isRecharge = true
            } else {
                ElMessage({
                    type: 'warning',
                    message: '温馨提示：您还没有开通该功能权限，如有需要请联系销售'
                })
            }
        },
        // 检测是否可以批量投保
        cantonCheckStatusWesure() {
            this.showtcflag = true
        },
        async onCheckStatusWesure() {
            const result = await checkStatusWesure()
            console.log(result, 'result')
            if (!result.status) {
                this.$message.error(result.msg)
                return
            }
            this.isInsure = true
        },
        // 获取余额信息
        async getInsureBalance() {
            const result = await insureBalance({
                insureCompanyType: 'insure002_gsc'
            })
            this.balanceObj = result
            console.log(result, '')
        },
        async insureDeductionList() {
            const result = await insureDeduction({
                beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                endCreateTime: this.projectTime ? this.projectTime[1] : '',
                searchText: this.searchText,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                insureCompanyType: 'insure002_gsc'
            })
            this.total = result.totalCount
            this.tableData = result.list
            this.insureCount = result.insureCount
        },
        handleSizeChange() {
            this.pageNum = 1
            this.insureDeductionList()
        },
        search() {
            this.pageNum = 1
            this.insureDeductionList()
        },
        onReset() {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange(selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
            console.log(this.selects, 'selects')
        },
        /**
         * 导出勾选
         */
        async onExport() {
            console.log('导出了')
            const result = await insureDeductionExport(
                {
                    beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                    endCreateTime: this.projectTime ? this.projectTime[1] : '',
                    searchText: this.searchText,
                    insureCompanyType: 'insure002_gsc'
                },
                {
                    loading: true
                }
            )
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async response => {
                    await downLoadFile(
                        response,
                        result.exportType,
                        result.updateTime,
                        result.remark
                    )
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     '/common/download?delete=true&fileName=' +
            //     result

            // this.$message.success('导出成功！')
        },
        async onSingleExport(id: string) {
            console.log(id, 'id', this.selects, 'selects')
            const result = await insureDeductionDetailExport({
                ids: this.selects.toString() || id
            })

            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result

            this.$message.success('导出成功！')
        },
        async onExportDetail() {
            const result = await insuranceDetailedExport(
                {
                    beginCreateTime: this.projectTime ? this.projectTime[0] : '',
                    endCreateTime: this.projectTime ? this.projectTime[1] : '',
                    searchText: this.searchText,
                    insureCompanyType: 'insure002_gsc'
                },
                {
                    loading: true
                }
            )
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async response => {
                    await downLoadFile(
                        response,
                        result.exportType,
                        result.updateTime,
                        result.remark
                    )
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //     '/common/download?delete=true&fileName=' +
            //     result

            // this.$message.success('导出成功！')
        },
        insureComplete() {
            this.isInsure = false
            this.handleSizeChange()
        },
        onCloseCallback() {
            this.isInsure = false
        },
        // 关闭弹窗
        onClose() {
            this.isRecharge = false
        },
        // 充值弹窗点击确定关闭
        async onDetermine(num: string, ocrNum:string, ocrImg: string, isRepeat: boolean) {
            if (!num) {
                this.$message.error('请输入金额')
                return
            }
            if (!ocrImg) {
                this.$message.error('请上传凭证')
                return
            }
            // fileName = fileName.split('base64,')[1]
            const result = await insuranceRecharge({
                rechargeAmount: num,
                ocrAmount: ocrNum,
                voucherUrl: ocrImg,
                receiptId: user.receiptId,
                isRepeat: isRepeat
            })
            if (result) {
                // this.isRecharge = false
                this.$message.success('提交成功')
            }
        },
        // 在insure-deduction页面调用insure-company页面的 方法 使用 ref
        onDialogVisible() {
            let dialogVisible = '' as any
            dialogVisible = this.$refs
            dialogVisible.insureCompany.onOpenDialogVisible()
        }
    }
})
