
import user from '@/utils/user'
import { defineComponent } from 'vue'
import {
    getInsureCompanyList,
    getInsurePlanList,
    InsuranceCompanyDto,
    PlanDto,
    batchInsure,
    insureInfoProjectNum,
    UserInfoDTOList,
    getInsured,
    searchCompanyDetailInfoApi
} from '@/api/insure-deduction'
import alertBox from './alert-box.vue'
import ExcelJS from 'exceljs'
import { looseIndexOf } from '@vue/shared'
import { number } from 'echarts'
import { formTesting, FormTestingErroeDto } from '@/utils/util'
import { ElMessage } from 'element-plus'
export default defineComponent({
    props: ['close'],
    components: { alertBox },
    data() {
        return {
            isDis1: false,
            isDis2: false,
            whichStep: 0,
            planName: '', // 投保方案名称
            planId: '' as string | number, // 投保方案Id
            planPrice: 0, // 投保方案价格
            planWesureType: '',
            planWesureName: '',
            planIndex: '',
            planList: [] as PlanDto[], // 投保方案列表
            conpanyName: '', // 投保企业名称
            companyId: '' as string | number, // 投保企业ID
            companyList: [] as any,
            fileList: [] as { name: string }[],
            userList: [] as {
                idCard: string;
                userName: string;
            }[],
            fileContentList: [] as {
                idCard: string;
                userName: string;
            }[],
            isOnBtn: true,
            dom: '',
            pageNum: 1,
            pageSize: 100,
            editIndex: '' as string | number,
            userIndex: 0,
            errorList: [] as {
                rowNum: number;
                alreadyInsured: boolean;
                isIdCardErrow: boolean;
                userName: string;
                idCard: string;
            }[], // 错误数据列表
            projectNum: 0,
            fileErrorList: [] as number[],
            fileFormatErroe: [] as { name: string; code: string }[],
            isFormError: false
        }
    },
    async created() {
        await this.getInsureCompanyList()
        // await this.searchCompanyDetailInfo(user.companyName, 1)
    },
    methods: {
        async nextStep() {
            // if (this.isDis1 || this.isDis2) {
            //     ElMessage.error('非常抱歉，当前方案仅支持(天津市静海区以外的)地区投保，请选择"其他方案"继续投保"')
            //     return
            // }
            if (this.fileErrorList.length > 0) {
                this.$message.error(
                    '(' +
                    this.fileErrorList.toString() +
                    ')' +
                    '  ' +
                    ' 该序号下信息不完整或无信息'
                )
                return
            }
            // this.whichStep = 1
            const arr = [] as any[]
            this.userList.forEach(item => {
                console.log(item, 'item')
                if (
                    typeof item.userName === 'object' ||
                    typeof item.idCard === 'object'
                ) {
                    const obj = formTesting(item.userName, item.idCard)
                    arr.push({
                        userName: obj.name,
                        idCard: obj.code
                    })
                } else {
                    arr.push(item)
                }
            })
            console.log(arr, 'arrarr')
            this.userList = arr
            const result = await insureInfoProjectNum()
            this.projectNum = result.projectNum
            // this.userList
            const result2 = await getInsured({
                serveCompanyId: this.companyId,
                gscUserInfoDTOList: arr
            })
            this.errorList = result2
            if (this.errorList.length !== 0) {
                this.whichStep = 0
            } else {
                this.whichStep = 1
            }
            this.fileErrorList = []
        },
        async getInsureCompanyList() {
            const result = await getInsureCompanyList()
            this.companyList = result
        },
        // TODO 日日保天津市静海区禁止投保
        async searchCompanyDetailInfo(name: string, type: number) {
            let res = await searchCompanyDetailInfoApi({
                throwException: false,
                keyword: name
            })
            console.log(res, 'fasdugbyfgyusdguy')
            if (res.district === '静海区' && res.city === '天津市') {
                ElMessage.error('非常抱歉，当前方案仅支持(天津市静海区以外的)地区投保，请选择"其他方案"继续投保"')
                if (type === 1) {
                    this.isDis1 = true
                } else if (type === 2) {
                    this.isDis2 = true
                }
            } else {
                if (type === 1) {
                    this.isDis1 = false
                } else if (type === 2) {
                    this.isDis2 = false
                }
            }
        },
        async onSelectCompany(e: number) {
            // TODO
            console.log(e, this.companyList, 'ghfusghy')
            // this.companyList.forEach((item: any) => {
            //     if (e === item.id) {
            //         console.log(1)
            //         this.searchCompanyDetailInfo(item.serveCompanyName, 2)
            //     }
            // })
            this.companyList.forEach((item: any, i: number) => {
                if (e === item.id && (item.isBlacklist || item.isForbidden)) {
                    this.companyId = ''
                }
            })
            if (this.companyId) {
                this.planList = await getInsurePlanList({
                    serveCompanyId: this.companyId as number,
                    productType: 0
                })
            } else {
                this.planList = []
            }
            this.planIndex = ''
            this.planId = ''
            this.planPrice = 0
            this.planName = ''
        },
        async onUpFile(file: File) {
            console.log(file, 'file')
            // if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            //     this.$message.error('请上传xlsx格式')
            //     return
            // }
            if (!file?.name?.endsWith('.xlsx')) {
                this.$message.error('请上传xlsx格式')
                return
            }
            //  as { userName: string; idCard: string }[]
            const userList = [] as { userName: string; idCard: string }[]
            const reader = new FileReader()
            reader.onload = async e => {
                const workbook = new ExcelJS.Workbook()
                await workbook.xlsx.load(
                    (e.target as FileReader).result as ArrayBuffer
                )
                workbook.getWorksheet(1).eachRow((item, index) => {
                    const fileError = formTesting(
                        item.getCell(2).value as string | FormTestingErroeDto[],
                        item.getCell(3).value as string | FormTestingErroeDto[]
                    )
                    console.log(fileError, 'fileError')
                    this.fileFormatErroe.push(fileError)
                    if (index <= 2) {
                        return item
                    }
                    if (item.getCell(2).value && item.getCell(3).value) {
                        console.log(item.getCell(2).value, 'item')
                        userList.push({
                            userName: item.getCell(2).value as string,
                            idCard: item.getCell(3).value as string
                        })
                    } else {
                        this.fileErrorList.push(
                            item.getCell(1).value
                                ? Number(item.getCell(1).value)
                                : 0
                        )
                    }
                })
                this.fileFormatErroe = this.fileFormatErroe.filter(
                    item => item.name
                )
                // if (this.fileFormatErroe.length > 0) {
                //     this.isFormError = true
                //     return this.fileFormatErroe.length
                // }
                for (let i = 0; i < userList.length; i++) {
                    for (let j = i + 1; j < userList.length; j++) {
                        if (userList[i].idCard === userList[j].idCard) {
                            userList.splice(j, 1)
                            j = j - 1
                        }
                    }
                }
                console.log(userList, 'userList')
                this.userList = userList
                console.log(this.userList, 'this.userList')
                this.handleCurrentChange(1)
                this.fileList.push({
                    name: file.name
                })
            }
            reader.readAsBinaryString(file)
        },
        // 选择方案
        onSelectInsure(index: number) {
            this.planId = this.planList[index].id
            this.planPrice = this.planList[index].price
            this.planWesureType = this.planList[index].wesurePlanCode
            this.planWesureName = this.planList[index].industryPlanName
        },
        tableRowClassName(row: { row: { isError: boolean } }) {
            console.log(row.row.isError)
            if (row.row.isError) {
                return 'warm-row'
            }
            return ''
        },
        // 上传文件编辑
        onFileEdit(event: number) {
            if (this.editIndex !== '' && this.editIndex !== event) {
                this.$message.error('请先保存')
                return
            }
            if (this.editIndex === event) {
                this.editIndex = ''
                // 按当前页删除一百条替换
                this.userList.splice(
                    (this.pageNum - 1) * 100,
                    100,
                    ...this.userList
                )
                console.log(this.userList)
            } else {
                this.editIndex = event
            }
        },
        onFileDelete(event: string) {
            console.log(event, 'eventevent')
            const arr = this.userList.map(item => {
                return item.idCard + ''
            })
            const arr2 = this.errorList.map(item => {
                return item.idCard
            })
            const index = arr.indexOf(event)
            const index2 = arr2.indexOf(event)
            this.userList.splice(index, 1)
            this.errorList.splice(index2, 1)
        },
        // 翻页
        handleCurrentChange(event: number) {
            this.pageNum = event
            this.fileContentList = this.userList.slice(
                (this.pageNum - 1) * 100,
                this.pageNum * 100
            )
            console.log(this.fileContentList, 'fileContentList')
        },
        async onAddInsurePay() {
            const result = await batchInsure({
                industryPlanId: this.planId as string,
                serveCompanyId: this.companyId as number,
                wesureType: this.planWesureType,
                wesureTypeName: this.planWesureName,
                userList: this.userList,
                projectNum: this.projectNum
            })
            if (result.code && result.code === 2) {
                this.$message.error(result.data)
                setTimeout(async () => {
                    this.whichStep -= 1
                    const result = await getInsured({
                        serveCompanyId: this.companyId,
                        gscUserInfoDTOList: this.userList
                    })
                    this.errorList = result
                }, 2000)
                return
            }
            this.whichStep += 1
        },
        onCloseEject() {
            this.$emit('close')
            this.$emit('complete')
        },
        onDeleteFile() {
            this.userList = []
            this.fileList = []
            this.fileErrorList = []
            this.errorList = []
            this.$message.success('删除成功')
        },
        onReset() {
            this.whichStep -= 1
            this.userList = []
            this.fileList = []
        },
        // 错误名单关闭回调
        onFormError() {
            this.fileFormatErroe = []
            console.log('弹窗关闭回调')
        },
        onCallback() {
            console.log('回调')
            this.$emit('close')
        }
    }
})
